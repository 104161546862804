import { useRouter } from 'next/router'
import Link from 'next/link'
import { FC, PropsWithChildren, useMemo } from 'react'
import { getUtms } from '@ui/helpers'

export type ISfLinkProps = {
  pathName: string
  className?: string
  query?: Types.PlainObject
  showVariant?: boolean
  disableForwardableQuery?: boolean
}

const SfLink: FC<PropsWithChildren<ISfLinkProps>> = (props) => {
  const router = useRouter()

  const buildQuery = useMemo(() => {
    let query: Types.PlainObject = { ...props.query }
    if (!props.disableForwardableQuery) {
      query = {
        ...query,
        ...getUtms(router.query),
        ...(router.query.page ? { page: router.query.page } : {}),
        ...(router.query.fbclid ? { fbclid: router.query.fbclid } : {}),
        ...(router.query.aleid ? { aleid: router.query.aleid } : {}),
        ...(router.query.gclid ? { gclid: router.query.gclid } : {}),
        ...(router.query.ttclid ? { ttclid: router.query.ttclid } : {}),
        ...(router.query.sclid ? { sclid: router.query.sclid } : {}),
        ...(router.query.i ? { sclid: router.query.i } : {}),
        ...(router.query.variant && props.showVariant ? { variant: router.query.variant } : {}),
        ...(router.query.vb && props.showVariant ? { vb: router.query.variant } : {}),
        ...(router.query.p ? { p: router.query.p } : {}),
        ...(router.query.s ? { utm_source: router.query.s } : {}),
        ...(router.query.m ? { utm_medium: router.query.m } : {}),
        ...(router.query.n ? { utm_campaign: router.query.n } : {}),
        ...(router.query.c ? { utm_content: router.query.c } : {}),
        ...(router.query.selless ? { selless: router.query.selless } : {}),
        ...(router.query.expiry ? { expiry: router.query.expiry } : {}),
        ...(router.query.wbraid ? { wbraid: router.query.wbraid } : {}),
        ...(router.query.gbraid ? { gbraid: router.query.gbraid } : {}),
        ...(router.query.epik ? { epik: router.query.epik } : {}),
        ...(router.query.pp ? { pp: router.query.pp } : {})
      }
    }

    return query
  }, [props])

  return (
    <Link href={{ pathname: props.pathName, query: buildQuery }}>
      <a className={props.className}>{props.children}</a>
    </Link>
  )
}

export default SfLink
